import * as i0 from '@angular/core';
import { Component, ChangeDetectionStrategy, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as i3 from '@ngx-formly/core';
import { FieldType, FormlyModule } from '@ngx-formly/core';
import * as i2 from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import * as i1 from 'primeng/checkbox';
import { CheckboxModule } from 'primeng/checkbox';
import { FormlyFormFieldModule } from '@ngx-formly/primeng/form-field';
class FormlyFieldCheckbox extends FieldType {
  constructor() {
    super(...arguments);
    this.defaultOptions = {
      props: {
        hideLabel: true
      }
    };
  }
}
FormlyFieldCheckbox.ɵfac = /* @__PURE__ */(() => {
  let ɵFormlyFieldCheckbox_BaseFactory;
  return function FormlyFieldCheckbox_Factory(__ngFactoryType__) {
    return (ɵFormlyFieldCheckbox_BaseFactory || (ɵFormlyFieldCheckbox_BaseFactory = i0.ɵɵgetInheritedFactory(FormlyFieldCheckbox)))(__ngFactoryType__ || FormlyFieldCheckbox);
  };
})();
FormlyFieldCheckbox.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: FormlyFieldCheckbox,
  selectors: [["formly-field-primeng-checkbox"]],
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 2,
  vars: 4,
  consts: [[1, "p-field-checkbox"], [3, "onChange", "binary", "label", "formControl", "formlyAttributes"]],
  template: function FormlyFieldCheckbox_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "div", 0)(1, "p-checkbox", 1);
      i0.ɵɵlistener("onChange", function FormlyFieldCheckbox_Template_p_checkbox_onChange_1_listener($event) {
        return ctx.props.change && ctx.props.change(ctx.field, $event);
      });
      i0.ɵɵelementEnd()();
    }
    if (rf & 2) {
      i0.ɵɵadvance();
      i0.ɵɵproperty("binary", true)("label", ctx.props.label)("formControl", ctx.formControl)("formlyAttributes", ctx.field);
    }
  },
  dependencies: [i1.Checkbox, i2.NgControlStatus, i2.FormControlDirective, i3.ɵFormlyAttributes],
  encapsulation: 2,
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FormlyFieldCheckbox, [{
    type: Component,
    args: [{
      selector: 'formly-field-primeng-checkbox',
      template: `
    <div class="p-field-checkbox">
      <p-checkbox
        [binary]="true"
        [label]="props.label"
        [formControl]="formControl"
        [formlyAttributes]="field"
        (onChange)="props.change && props.change(field, $event)"
      >
      </p-checkbox>
    </div>
  `,
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], null, null);
})();
class FormlyCheckboxModule {}
FormlyCheckboxModule.ɵfac = function FormlyCheckboxModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || FormlyCheckboxModule)();
};
FormlyCheckboxModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: FormlyCheckboxModule
});
FormlyCheckboxModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule, ReactiveFormsModule, CheckboxModule, FormlyFormFieldModule, FormlyModule.forChild({
    types: [{
      name: 'checkbox',
      component: FormlyFieldCheckbox,
      wrappers: ['form-field']
    }, {
      name: 'boolean',
      extends: 'checkbox'
    }]
  })]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FormlyCheckboxModule, [{
    type: NgModule,
    args: [{
      declarations: [FormlyFieldCheckbox],
      imports: [CommonModule, ReactiveFormsModule, CheckboxModule, FormlyFormFieldModule, FormlyModule.forChild({
        types: [{
          name: 'checkbox',
          component: FormlyFieldCheckbox,
          wrappers: ['form-field']
        }, {
          name: 'boolean',
          extends: 'checkbox'
        }]
      })]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { FormlyCheckboxModule, FormlyFieldCheckbox };
