import * as i0 from '@angular/core';
import { Component, ChangeDetectionStrategy, NgModule } from '@angular/core';
import * as i2 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i1$1 from '@ngx-formly/core';
import { FieldType, FormlyModule } from '@ngx-formly/core';
import * as i3 from '@angular/forms';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import * as i4 from '@ngx-formly/core/select';
import { FormlySelectModule } from '@ngx-formly/core/select';
import * as i1 from 'primeng/radiobutton';
import { RadioButtonModule } from 'primeng/radiobutton';
import { FormlyFormFieldModule } from '@ngx-formly/primeng/form-field';
function FormlyFieldRadio_div_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 1);
    i0.ɵɵelement(1, "p-radioButton", 2);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const option_r1 = ctx.$implicit;
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("name", ctx_r1.field.name || ctx_r1.id)("formControl", option_r1.disabled ? ctx_r1.disabledControl : ctx_r1.formControl)("label", option_r1.label)("value", option_r1.value);
  }
}
class FormlyFieldRadio extends FieldType {
  get disabledControl() {
    return new FormControl({
      value: this.formControl.value,
      disabled: true
    });
  }
}
FormlyFieldRadio.ɵfac = /* @__PURE__ */(() => {
  let ɵFormlyFieldRadio_BaseFactory;
  return function FormlyFieldRadio_Factory(__ngFactoryType__) {
    return (ɵFormlyFieldRadio_BaseFactory || (ɵFormlyFieldRadio_BaseFactory = i0.ɵɵgetInheritedFactory(FormlyFieldRadio)))(__ngFactoryType__ || FormlyFieldRadio);
  };
})();
FormlyFieldRadio.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: FormlyFieldRadio,
  selectors: [["formly-field-primeng-radio"]],
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 3,
  vars: 6,
  consts: [["class", "p-field-radiobutton", 4, "ngFor", "ngForOf"], [1, "p-field-radiobutton"], [3, "name", "formControl", "label", "value"]],
  template: function FormlyFieldRadio_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, FormlyFieldRadio_div_0_Template, 2, 4, "div", 0);
      i0.ɵɵpipe(1, "formlySelectOptions");
      i0.ɵɵpipe(2, "async");
    }
    if (rf & 2) {
      i0.ɵɵproperty("ngForOf", i0.ɵɵpipeBind1(2, 4, i0.ɵɵpipeBind2(1, 1, ctx.props.options, ctx.field)));
    }
  },
  dependencies: [i1.RadioButton, i2.NgForOf, i3.NgControlStatus, i3.FormControlDirective, i2.AsyncPipe, i4.FormlySelectOptionsPipe],
  encapsulation: 2,
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FormlyFieldRadio, [{
    type: Component,
    args: [{
      selector: 'formly-field-primeng-radio',
      template: `
    <div class="p-field-radiobutton" *ngFor="let option of props.options | formlySelectOptions : field | async">
      <p-radioButton
        [name]="field.name || id"
        [formControl]="option.disabled ? disabledControl : formControl"
        [label]="option.label"
        [value]="option.value"
      >
      </p-radioButton>
    </div>
  `,
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], null, null);
})();
class FormlyRadioModule {}
FormlyRadioModule.ɵfac = function FormlyRadioModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || FormlyRadioModule)();
};
FormlyRadioModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: FormlyRadioModule
});
FormlyRadioModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule, ReactiveFormsModule, RadioButtonModule, FormlyFormFieldModule, FormlySelectModule, FormlyModule.forChild({
    types: [{
      name: 'radio',
      component: FormlyFieldRadio,
      wrappers: ['form-field']
    }]
  })]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FormlyRadioModule, [{
    type: NgModule,
    args: [{
      declarations: [FormlyFieldRadio],
      imports: [CommonModule, ReactiveFormsModule, RadioButtonModule, FormlyFormFieldModule, FormlySelectModule, FormlyModule.forChild({
        types: [{
          name: 'radio',
          component: FormlyFieldRadio,
          wrappers: ['form-field']
        }]
      })]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { FormlyFieldRadio, FormlyRadioModule };
